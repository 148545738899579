import React from 'react'
import { tealslogo, tealsspelling } from '../icons/icon';

const Loadingteals = () => {
    return (
        <main className='container vh-100 screen_size_responsive bg_light d-flex justify-content-center align-items-center'>
            <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                <img src={tealslogo} style={{ width: '100px', height: '100px' }} className='mb-3' alt="" />
                <img src={tealsspelling} style={{ height: '24px', width: 'auto' }} alt="" />
                <span className="inter_semibold text_dark">Simply Good Deals</span>
            </div>
        </main>
    )
}

export default Loadingteals;