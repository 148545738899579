import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teals_userData: {
    isLogin: false,
    token: "",
    user: {},
    latlng: {},
    tempUser: null,
    code: '',
    phone: "",
  },
  isGuest: true,
  admin: '',
  mongo_user: null,
  buyCredits: null
};

export const userAuthSlice = createSlice({
  name: "teals_teals_userData",
  initialState: initialState,
  reducers: {
    emptyToken(state, action) {
      state.teals_userData.token = initialState.teals_userData.token;
    },
    emptyUser(state, action) {
      state.teals_userData.user = initialState.teals_userData.user;
      state.mongo_user = null;
    },
    setIsGuest(state, action) {
      state.isGuest = action.payload;
    },
    setMongoUser(state, action) {
      state.mongo_user = action.payload;
    },
    setAdminId(state, action) {
      state.admin = action.payload;
    },
    setBuyCredits(state, action) {
      state.buyCredits = action.payload;
    },
    getToken(state, action) {
      state.teals_userData.token = action.payload;
    },
    setTempUser(state, action) {
      state.teals_userData.tempUser = action.payload;
    },
    getCode(state, action) {
      state.teals_userData.code = action.payload;
    },
    getPhonenumber(state, action) {
      state.teals_userData.phone = action.payload;
    },
    getUser(state, action) {
      state.teals_userData.user = action.payload;
    },
    getCurrentLatLng(state, action) {
      state.teals_userData.latlng = action.payload;
    },
    setIsLogin(state, action) {
      state.teals_userData.isLogin = action.payload
    },
  },
});

export const { getToken, getUser, setBuyCredits, setAdminId, setIsGuest, setTempUser, setMongoUser, getPhonenumber, setNavigation, getCurrentLatLng, setIsLogin, emptyUser, emptyToken, getCode } = userAuthSlice.actions;

export default userAuthSlice.reducer;
