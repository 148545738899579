import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  business: {},
  couponData: null,
  payment: null,
  redeem: null,
  // retrieve: {},
  // cat: '',
  // logo: ''
};
export const businessSlice = createSlice({
  name: "teals_business",
  initialState: initialState,
  reducers: {
    getBusiness(state, action) {
      state.business = action.payload;
    },
    setRadeemCouponData(state, action) {
      state.couponData = action.payload;
    },
    setRadeemData(state, action) {
      state.redeem = action.payload;
    },
    setPayment(state, action) {
      state.payment = action.payload;
    },
    // retrieveData(state, action) {
    //   state.retrieve = action.payload;
    // },
    // retrieveLogo(state, action) {
    //   state.logo = action.payload;

    // },
    // getCat(state, action) {
    //   state.cat = action.payload;
    // },
  },
});

export const { getBusiness, setRadeemCouponData, setPayment, setRadeemData } = businessSlice.actions;
