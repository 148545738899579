import { combineReducers } from 'redux';
import { productSlice } from './productSlice';
import { userAuthSlice } from './userAuthSlice';
import { businessSlice } from './businessSlice';
export const rootReducer = combineReducers({
  teals_store: productSlice.reducer,
  teals_userData: userAuthSlice.reducer,
  teals_business: businessSlice.reducer

});

