/* eslint-disable no-unused-vars */
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './components/styles/main.css'
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Loadingteals from './components/pages/loadingteals';
import { tealslogo, tealsspelling } from './components/icons/icon';
import store, { persistor } from './components/store';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { APIProvider } from '@vis.gl/react-google-maps';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/extension/i18n';
import { HelmetProvider } from 'react-helmet-async';
const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense
    fallback={
      <main className='screen_size_responsive bg_light main_loader_style'>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', gap: '4px' }}>
          <img src={tealslogo} style={{ width: '100px', height: '100px', marginBottom: '1.5rem' }} alt="" />
          <img src={tealsspelling} style={{ height: '24px', width: 'auto' }} alt="" />
          <span>Simply Good Deals</span>
        </div>
      </main>
    }>
    {/* <Provider store={store}> */}
    <I18nextProvider i18n={i18n}>

      <BrowserRouter>
        <React.StrictMode>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </PersistGate>
          </Provider>
          {/* <ToastContainer newestOnTop /> */}
        </React.StrictMode>
      </BrowserRouter>
    </I18nextProvider>
    {/* </Provider> */}
  </Suspense >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
